import TerminalButton from '@/Components/Buttons/TerminalButton';
import VideoPlayer from '@/Components/Modals/VideoPlayer';
import styles from '@/styles/Main/Home/Home.module.scss';
import { MobileContext } from '@/utils/DetectMobileContext';
import { ImageToBase64, LoaderShimmer } from '@/utils/imageHelpers';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useContext, useState } from 'react';
const Errorpage = () => {
  const router = useRouter();
  const [videoPlay, setvideoPlay] = useState(false);
  const { isMobile } = useContext(MobileContext);
  return (
    // <div className={styles.mainHomecontainer}>
    //   <MainHeader />
    //   <MainContainer>
    <div className={styles.messageContainer}>
      <div className={styles.messageContainer_left}>
        <h1>Oops!</h1>
        <p>
          It seems you&apos;ve stumbled upon an uncharted territory in the world
          of real estate. <br /> <br />
          Don&apos;t worry, at Broker Terminal, we&apos;re experts in finding
          homes and creating happiness. <br /> <br />
          While this page may be temporarily unavailable, our dedicated team is
          always here to guide you towards your dream property in Dubai. Sit
          back, relax, and let us take you on a journey to find your perfect
          home. Together, we&apos;ll turn your dreams into reality!
        </p>
        <TerminalButton
          label="Back to Home"
          onClick={() => router.push('/')}
          width={isMobile ? '90%' : 'inhetr'}
          position={isMobile ? 'abs' : ''}
        />
      </div>
      <div className={styles.messageContainer_right}>
        <Image
          placeholder={`data:image/svg+xml;base64,${ImageToBase64(
            LoaderShimmer(700, 475)
          )}`}
          src={'/assets/Images/404/img.svg'}
          alt="404"
          width={350}
          height={600}
        />
        <Image
          placeholder={`data:image/svg+xml;base64,${ImageToBase64(
            LoaderShimmer(700, 475)
          )}`}
          src={'/assets/icons/videowatch.svg'}
          alt="watch"
          width={100}
          onClick={() => setvideoPlay(true)}
          height={100}
        />
      </div>
      {videoPlay && <VideoPlayer setPlay={setvideoPlay} play={videoPlay} />}
    </div>
    //   </MainContainer>

    // </div>
  );
};

export default Errorpage;
